<template lang="pug">
app-layout
  .page-not-found
    .page-not-found__content
      titled-image.mb-8(image="404" title="Страница не найдена" width=160)
        div Неправильно набран адрес, или такой страницы больше не существует

      ui-btn.page-not-found__action(
        large
        secondary
        width=233
        @click="goHome"
      ) Перейти на главную

    get-apps(centered)
</template>

<script>
  import AppLayout from '@/layouts/AppLayout';
  import { UiBtn } from '@/uikit';
  import GetApps from '@/components/app/GetApps.vue';
  import TitledImage from '@/components/TitledImage.vue';

  export default {
    name: 'page-not-found',
    components: { AppLayout, UiBtn, GetApps, TitledImage },

    methods: {
      goHome() {
        this.$router.push(this.$path.home());
      },
    },
  };
</script>

<style lang="scss">
  .page-not-found {
    position: fixed;
    background-color: c('light', 'light');
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 16px;
    height: 100%;
    width: 100%;

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
</style>
